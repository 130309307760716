import "bond_assets/public/node_modules/jquery-nice-select/js/jquery.nice-select.js";

import momentTimezone from "moment-timezone/builds/moment-timezone-with-data.js";
window.moment = momentTimezone;

import "bond_assets/public/node_modules/jquery.maskedinput/src/jquery.maskedinput";
import "bond_assets/public/node_modules/quill/dist/quill";
import "bond_assets/public/node_modules/simplebar/dist/simplebar.min";
import "bond_assets/public/node_modules/@lottiefiles/lottie-player/dist/lottie-player";

import Vue from "vue";
window.Vue = Vue;

(function($){
    $.fn.niceSelect = $.fn.niceSelect;
})(jQuery)